import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.scss';
import Room from './components/Room';
import { Home } from './Pages';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/discord" component={Home} />
        <Route path="/nerdoffice" component={Home} />
        <Route path="/room/discord" component={Room} />
        <Route path="/room/nerdoffice" component={Room} />
        <Route path="/" exact component={Home} />
        <Route path="/" component={() => <div>to do 404...</div>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
