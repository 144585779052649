import React from 'react';
import './Home.scss';
import { useHistory } from 'react-router-dom';

export default function () {
  const history = useHistory();

  return (
    <div className="Home">
      <div className="logo">
        <img src={require('../../assets/images/logo.png')} alt="logo" />
      </div>

      <div className="button-group">
        <div
          className="neon-button"
          onClick={() => {
            history.push('/room/nerdoffice');
          }}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Enter Nerdoffice Room
        </div>

        <div
          className="neon-button"
          onClick={() => {
            history.push('/room/discord');
          }}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Enter Discord Room
        </div>
      </div>
    </div>
  );
}
