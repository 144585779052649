import React, { useRef, useEffect } from 'react';
import './Peer.scss';

export default function Peer(props: { peer: { userId: string; stream: MediaStream } }) {
  const peerVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const peerVideo = peerVideoRef.current;
    if (peerVideo) {
      peerVideo.srcObject = props.peer.stream;
      peerVideo.muted = false;
      peerVideo.autoplay = true;
      peerVideo.volume = 0.35;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="peer-video">
      <video id="peer" playsInline ref={peerVideoRef} />
      <audio autoPlay src={require('../../assets/sounds/enter_sound.mp3')} />
    </div>
  );
}
