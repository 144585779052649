import React, { useEffect, useRef, useState } from 'react';
import './Room.scss';
import io from 'socket.io-client';
import Peerjs from 'peerjs';
import Peer from '../../components/Peer';
import { useLocation } from 'react-router-dom';

function Room() {
  const { pathname } = useLocation();
  const [peersList, setPeersList] = useState(new Map());
  const myVideoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const socket = io('https://api.cwbrand.com.br', { transports: ['websocket'] });
    const myPeer = new Peerjs(undefined, {
      host: 'broker.cwbrand.com.br',
      secure: true,
      path: '/tww'
    });

    const peers: any = {};
    let myStream: MediaStream;

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then(stream => {
        myStream = stream;
        const myVideo = myVideoRef?.current;
        if (myVideo) {
          myVideo.srcObject = stream;
          myVideo.muted = true;
          myVideo.autoplay = true;
        }

        myPeer.on('call', call => {
          call.answer(stream);
          call.on('stream', peerStream => {
            savePeer(call.peer, peerStream);
          });
        });

        socket.on('user-connected', (userId: string) => {
          connectToNewUser(userId, stream);
        });
      })
      .catch(console.error);

    socket.on('user-disconnected', (userId: string) => {
      removePeer(userId);
      if (peers[userId]) peers[userId].close();
    });

    myPeer.on('open', id => {
      socket.emit('join-room', pathname, id);
    });

    function connectToNewUser(userId: string, stream: MediaStream) {
      const call = myPeer.call(userId, stream);

      call.on('stream', userVideoStream => {
        savePeer(userId, userVideoStream);
      });

      call.on('close', () => {
        removePeer(userId);
      });

      peers[userId] = call;
    }

    function savePeer(userId: string, stream: MediaStream): void {
      setPeersList(new Map(peersList.set(userId, stream)));
    }

    function removePeer(userId: string): void {
      peersList.delete(userId);
      setPeersList(new Map(peersList));
      audioRef.current?.play();
    }

    // @ts-ignore
    new Twitch.Embed('twitch-embed', {
      width: '100%',
      height: '100%',
      channel: 'gaules',
      layout: 'video'
    });

    return () => {
      myPeer.disconnect();
      socket.disconnect();
      myStream.getTracks().forEach(track => {
        track.stop();
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [peersList]);

  return (
    <div className="Room">
      <div className="twitch-container">
        <div className="twitch" id="twitch-embed"></div>
      </div>

      <div className="peers-container">
        <div className="peer-video">
          <video playsInline id="my-video" ref={myVideoRef} />
          <audio autoPlay src={require('../../assets/sounds/enter_sound.mp3')} />
        </div>

        <audio ref={audioRef} src={require('../../assets/sounds/leave_sound.mp3')} />

        {peersList.size
          ? [...peersList.keys()].map(key => (
              <Peer key={key} peer={{ userId: key, stream: peersList.get(key) }} />
            ))
          : null}
      </div>
    </div>
  );
}

export default Room;
